import { Turnstile } from '@marsidev/react-turnstile';
import { useMask } from '@react-input/mask';
import { generateLeadURL, getCookieGoogleId, getCookieMsclkid } from 'app/actions/affiliate.actions';
import { siteVerification } from 'app/actions/cloudflare.actions';
import { getTurnstileKey } from 'app/actions/turnstile.actions';
import { Label, TextInput } from 'flowbite-react';
import { useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';

interface RequestQuoteWizardFormProps {
  form: any;
  sourceKey: string;
  setForm: (value: any) => void;
  variant: string;
}

export function RequestQuoteWizardForm(props: RequestQuoteWizardFormProps) {
  const [turnstileToken, setTurnstileToken] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [turnstileKey, setTurnstileKey] = useState<string | null>(null);

  useEffect(() => {
    setTurnstileKey(getTurnstileKey());
  }, []);

  const router = useRouter();
  const searchParams = useSearchParams();
  const { form, variant, sourceKey } = props;

  const inputRef = useMask({
    mask: '(___) ___-____',
    replacement: { _: /\d/ }
  });

  async function createQuote(formData: FormData) {
    if (!turnstileToken) {
      setError('Please verify you are not a robot');
      return;
    }
    let gclid = await getCookieGoogleId()
    let msclkid = await getCookieMsclkid()

    try {
      const status = await siteVerification(turnstileToken);

      if (status === 200) {
        const rawFormData = {
          firstName: formData.get('firstName'),
          lastName: formData.get('lastName'),
          email: formData.get('email'),
          phone: formData.get('phone')
        };

        const payload = {
          contact: {
            isResidential: true,
            firstName: rawFormData.firstName,
            lastName: rawFormData.lastName,
            email: rawFormData.email,
            phone: {
              number: rawFormData.phone
            }
          },
          compliance: {
            tcpaPermissionGranted: true,
            tcpaPermissionLink: ''
          },
          additionalFields: {
            FIRSTNAME: rawFormData.firstName,
            LASTNAME: rawFormData.lastName,
            EMAIL: rawFormData.email,
            PHONE: rawFormData.phone,
            submissionURL: window.location.href,
            ...form
          } as any
        };

        const subid = searchParams?.get('subid');
        if (subid) {
          payload.additionalFields['subid'] = subid;
        }

        const leadURL = await generateLeadURL(sourceKey);
        if (gclid !== undefined) payload.additionalFields['googleclickid'] = gclid
        if (msclkid !== undefined) payload.additionalFields['msclkid'] = msclkid

        if (leadURL) {
          const response = await fetch(leadURL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
          });
          if (response.status === 200) {
            router.push('/thank-you');
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div>
      {variant === 'default' && (
        <div className="flex flex-col items-center">
          <div className="w-full text-center">
            <p>Speak with a Security Advisor:</p>
            <p>or</p>
            <p>Let us reach out and customize a quote for you</p>
          </div>

          <form className="mt-4 flex w-full flex-col gap-1 px-20" action={createQuote}>
            <div className="flex w-full gap-2">
              <div className="flex-1">
                <div className="block">
                  <Label htmlFor="firstName" value="First Name" />
                </div>
                <TextInput name="firstName" type="text" required shadow />
              </div>
              <div className="flex-1">
                <div className="block">
                  <Label htmlFor="lastName" value="Last Name" />
                </div>
                <TextInput name="lastName" type="text" required shadow />
              </div>
            </div>
            <div>
              <div className="block">
                <Label htmlFor="phone" value="Phone Number" />
              </div>
              <TextInput pattern="\([0-9]{3}\) [0-9]{3}-[0-9]{4}" ref={inputRef} name="phone" type="text" required shadow />
            </div>
            <div>
              <div className="block">
                <Label htmlFor="email" value="Your email" />
              </div>
              <TextInput name="email" type="email" required shadow />
            </div>
            <div className="mt-2 flex w-full flex-col items-center justify-center">
              {turnstileKey && (
                <Turnstile
                  siteKey={turnstileKey}
                  onError={(e) => {
                    setTurnstileToken('');
                  }}
                  onExpire={(e) => {
                    setTurnstileToken('');
                  }}
                  onSuccess={(e) => {
                    setTurnstileToken(e);
                  }}
                />
              )}

              <button
                type="submit"
                className="f flex w-full max-w-48 items-center justify-center rounded-md border-2 bg-fp-green p-2 font-bold text-white hover:border-2 hover:border-fp-green hover:bg-white hover:text-fp-green"
              >
                Submit
              </button>
            </div>
          </form>
          <div className="w-full px-10 pt-4 text-justify">
            <p className="text-[12px]">
              By submitting your contact information above, you authorize FrontPoint Security
              Solutions, LLC and its affiliates, including Frontpoint Security Solutions, LP
              (collectively, “Frontpoint”) to send you marketing communications (by mail, email,
              telephone, text message, or voicemail transmission). Calls and text messages at the
              telephone number you provided above may be made using an automatic telephone dialing
              system or automated system for the selection of telephone numbers and may play a
              recorded message when a connection is complete or transmit a pre-recorded voicemail.
              Your consent is not required as a condition of purchase of a Frontpoint security
              system. Message and data rates may apply. You may unsubscribe at any time.
            </p>
          </div>
        </div>
      )}

      {variant === 'lifecare' && (
        <>
          <div className="items-left flex flex-col">
            <div className="text-left">
              <p className="text-[22px] font-bold text-fp-green">Get Your Free Quote</p>
            </div>

            <form className="mt-4 flex w-full flex-col gap-1" action={createQuote}>
              <div>
                <div className="block">
                  <Label htmlFor="firstName" value="First Name" />
                </div>
                <TextInput
                  className="[&_*]:rounded-3xl [&_*]:bg-fp-sand"
                  name="firstName"
                  type="text"
                  required
                  shadow
                />
              </div>
              <div>
                <div className="block">
                  <Label htmlFor="lastName" value="Last Name" />
                </div>
                <TextInput
                  className="[&_*]:rounded-3xl [&_*]:bg-fp-sand"
                  name="lastName"
                  type="text"
                  required
                  shadow
                />
              </div>
              <div>
                <div className="block">
                  <Label htmlFor="phoneNumber" value="Phone Number" />
                </div>
                <TextInput
                  className="[&_*]:rounded-3xl [&_*]:bg-fp-sand"
                  pattern="\([0-9]{3}\) [0-9]{3}-[0-9]{4}"
                  ref={inputRef}
                  name="phone"
                  type="text"
                  required
                  shadow
                />
              </div>
              <div>
                <div className="block">
                  <Label htmlFor="email" value="Your email" />
                </div>
                <TextInput
                  className="[&_*]:rounded-3xl [&_*]:bg-fp-sand"
                  name="email"
                  type="email"
                  required
                  shadow
                />
              </div>
              <div className="mt-2 flex w-full justify-center">
                <button
                  type="submit"
                  className="f flex w-full items-center justify-center rounded-3xl border-2 bg-fp-green p-3 font-bold text-white hover:border-2 hover:border-fp-green hover:bg-white hover:text-fp-green"
                >
                  Get a Free Quote Now
                </button>
              </div>
            </form>
            <div className="w-full pt-2 text-justify">
              <div className="flex justify-center">
                {turnstileKey && (
                  <Turnstile
                    siteKey={turnstileKey}
                    onError={(e) => {
                      setTurnstileToken('');
                    }}
                    onExpire={(e) => {
                      setTurnstileToken('');
                    }}
                    onSuccess={(e) => {
                      setTurnstileToken(e);
                    }}
                  />
                )}
              </div>
              <p className="text-[9px]">
                By submitting your contact information above, you authorize FrontPoint Security
                Solutions, LLC and its affiliates, including Frontpoint Security Solutions, LP
                (collectively, “Frontpoint”) to send you marketing communications (by mail, email,
                telephone, text message, or voicemail transmission). Calls and text messages at the
                telephone number you provided above may be made using an automatic telephone dialing
                system or automated system for the selection of telephone numbers and may play a
                recorded message when a connection is complete or transmit a pre-recorded voicemail.
                Your consent is not required as a condition of purchase of a Frontpoint security
                system. Message and data rates may apply. You may unsubscribe at any time.
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
