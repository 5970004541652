'use client';
import { Turnstile } from '@marsidev/react-turnstile';
import { useMask } from '@react-input/mask';
import {
  generateLeadURL,
  getCookieGoogleId,
  getCookieMsclkid
} from 'app/actions/affiliate.actions';
import { siteVerification } from 'app/actions/cloudflare.actions';
import { getTurnstileKey } from 'app/actions/turnstile.actions';
import { ButtonGreen } from 'components/buttons/default-green';
import { Modal, TextInput } from 'flowbite-react';
import Link from 'next/link';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import { AffiliateList } from 'types/builder';


type DataProps = {
  link: string;
  heading: string;
  excludedRoutes: {
    route: string;
  }[];
};

interface DynamicForm {
  [key: string]: string;
}

export function TopBannerSegmentItem({
  data,
  affiliateList,
  sourceKey,
  phoneSourceKey
}: {
  data: DataProps;
  affiliateList: AffiliateList | undefined;
  sourceKey: string;
  phoneSourceKey: string;
}) {
  const router = useRouter();
  const searchParams = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const [show, setShow] = useState(true);
  const [showThankYou, setShowThankYou] = useState(false);
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [turnstileKey, setTurnstileKey] = useState<string | null>(null);
  const [form, setForm] = useState<DynamicForm>({
    gaMedium: 'referral',
    gaSource: '',
    gaContent: '/',
    gaClientId: '970385394.1727054325',
    gaCampaign: '(referral)'
  });
  const [showPromotionalModal, setShowPromotionalModal] = useState(false);

  const inputRef = useMask({
    mask: '(___) ___-____',
    replacement: { _: /\d/ }
  });

  useEffect(() => {
    setTurnstileKey(getTurnstileKey());
  }, []);

  const pathname = usePathname();
  const { link, heading, excludedRoutes } = data;
  const flatRoutes = excludedRoutes.map((route) => route.route);

  useEffect(() => {
    const isAffiliate = affiliateList?.includes(pathname);
    const isExcluded = flatRoutes.some((route) => pathname.includes(route));
    isAffiliate || isExcluded ? setShow(false) : setShow(true);
  }, [pathname]);

  if (!show) return null;

  async function createQuote(turnstileToken: string) {
    if (!turnstileToken) return;

    try {
      const status = await siteVerification(turnstileToken);
      let gclid = await getCookieGoogleId();
      let msclkid = await getCookieMsclkid();

      if (status === 200) {
        const payload = {
          contact: {
            isResidential: true,
            email
          },
          compliance: {
            tcpaPermissionGranted: true,
            tcpaPermissionLink: ''
          },
          additionalFields: {
            EMAIL: email,
            submissionURL: window.location.href,
            ...form
          } as any
        };

        const subid = searchParams?.get('subid');
        if (subid) {
          payload.additionalFields['subid'] = subid;
        }

        if (gclid !== undefined) payload.additionalFields['googleclickid'] = gclid;
        if (msclkid !== undefined) payload.additionalFields['msclkid'] = msclkid;
        const leadURL = await generateLeadURL(sourceKey);

        if (leadURL) {
          const response = await fetch(leadURL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
          });
          if (response.status === 200) {
            setShowThankYou(true);
            setShowPromotionalModal(true);
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  const submitPhone = async () => {
    if (!phone) return;
    try {
      let gclid = await getCookieGoogleId();
      let msclkid = await getCookieMsclkid();

      const payload = {
        contact: {
          isResidential: true,
          email,
          phone: {
            number: phone
          }
        },
        compliance: {
          tcpaPermissionGranted: true,
          tcpaPermissionLink: ''
        },
        additionalFields: {
          EMAIL: email,
          PHONE: phone,
          submissionURL: window.location.href,
          ...form
        } as any
      };

      const subid = searchParams?.get('subid');
      if (subid) {
        payload.additionalFields['subid'] = subid;
      }

      if (gclid !== undefined) payload.additionalFields['googleclickid'] = gclid;
      if (msclkid !== undefined) payload.additionalFields['msclkid'] = msclkid;
      const leadURL = await generateLeadURL(phoneSourceKey);

      if (leadURL) {
        const response = await fetch(leadURL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        });
        if (response.status === 200) {
          setEmail('');
          setPhone('');
          setShowPromotionalModal(false);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="container mx-auto !px-0">
      <div className="flex w-full flex-col items-center justify-between bg-fp-green py-3 md:flex-row lg:py-1">
        <div className="py:5 px-6 py-1 text-white">
          <h2 className="text-center text-[18px] font-medium md:text-left">
            <Link href={link}>{heading}</Link>
          </h2>
        </div>
        <div className="flex h-full min-h-[140px] flex-col justify-center text-white md:py-2 lg:mr-10 lg:min-h-[80px] lg:min-w-[300px]">
          {!showThankYou && (
            <>
              <div className="flex w-full flex-col items-center pr-3 md:items-start lg:flex-row lg:items-center">
                <form>
                  <input
                    type="email"
                    className="text-gray-900"
                    name="email"
                    value={email}
                    placeholder="Enter your email address"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </form>
                <button
                  className="mt-4 underline lg:ml-3 lg:mt-0"
                  onClick={() => {
                    if (email) {
                      setIsOpen(true);
                      setMessage('');
                    } else {
                      setMessage('Please enter a valid email address');
                    }
                  }}
                >
                  Get Offer
                </button>
              </div>
              {message && message !== '' && (
                <div className="mt-1 w-fit rounded-sm bg-white">
                  <span className="mx-2 text-[10px] text-red-700">{message}</span>
                </div>
              )}
              <p className="mt-1 text-[10px]">
                Get offer plus promotions and updates. Review our{' '}
                <Link className="underline" href={'/privacy-policy'}>
                  {' '}
                  privacy policy.
                </Link>
              </p>
            </>
          )}

          {showThankYou && (
            <div className="mr-10 flex flex-col items-center text-nowrap">
              <button
                className="bg-[#fff] px-1 font-medium text-black"
                onClick={() => {
                  setShowThankYou(false);
                  router.push('/shop');
                }}
              >
                Shop now
              </button>
              <p className="mt-1 text-[10px]">Thank you, your email offer is on the way!</p>
            </div>
          )}
        </div>
      </div>
      <Modal show={isOpen} size="sm" onClose={() => setIsOpen(false)}>
        <Modal.Body>
          {turnstileKey && (
            <Turnstile
              siteKey={turnstileKey}
              onSuccess={(e) => {
                setIsOpen(false);
                createQuote(e);
              }}
            />
          )}
        </Modal.Body>
      </Modal>

      <Modal show={showPromotionalModal} size="lg" onClose={() => setShowPromotionalModal(false)}>
        <Modal.Header className="!absolute right-0 z-10 border-none" />
        <Modal.Body>
          <div className="flex flex-col items-center gap-4">
            <div className="text-sm">Thank You! Your email with offer details is on the way.</div>
            <div className="flex flex-col items-center gap-2 rounded-md border border-dashed border-black p-5">
              <div className="text-lg font-bold">
                Use Code <span className="text-fp-green">SAVE25</span>
              </div>
              <div className="text-sm font-bold">
                At Checkout for $25 OFF Your Purchase of $250 or More{' '}
              </div>
              <div className="text-sm">Use Your Offer to Buy Now Online</div>
              <ButtonGreen href="/shop" className="rounded-md bg-fp-green p-2 text-white">
                Shop Systems
              </ButtonGreen>
            </div>
            <div className="text-sm">
              OR Talk to a Security Specialist to Unlock Further Savings
            </div>
            <div className="flex flex-row gap-2 w-72">
              <TextInput
                pattern="\([0-9]{3}\) [0-9]{3}-[0-9]{4}"
                 placeholder="Enter your phone number"
                ref={inputRef}
                name="phone"
                type="text"
                required
                shadow
                className="w-full"
                onChange={(e) => setPhone(e.target.value)}
              />
              <button className="rounded-md bg-fp-green p-2 text-white" onClick={submitPhone}>
                Submit
              </button>
            </div>
            <div className="text-sm">Or Call Us</div>
            <ButtonGreen href="tel:844-759-3009" className="rounded-md bg-fp-green p-2 text-white">
              <div className="flex flex-row items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="size-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="ml-2">844-759-3009</span>
              </div>
            </ButtonGreen>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
