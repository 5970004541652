'use client';

import { setgoogleIdCookies } from 'app/actions/affiliate.actions';
import { usePathname, useSearchParams } from "next/navigation";
import { ReactNode, use, useEffect, useState } from "react";
import { AffiliateList } from 'types/builder';
import { cn } from 'utils/cn';



export function NavbarWrapper({ affiliatePromise, children }: { affiliatePromise: Promise<AffiliateList | undefined>, children: ReactNode }) {
    const [affiliate, setAffiliate] = useState(false)
    const pathname = usePathname()
    const affiliateList = use(affiliatePromise);
    const searchParams = useSearchParams();    
    useEffect(() => {
      const gclid = searchParams.get('gclid');
      const msclkid = searchParams.get('msclkid');
      if (gclid) {
        setgoogleIdCookies({gclid});
      }
      if (msclkid) {
        setgoogleIdCookies({msclkid});
      }

      affiliateList?.includes(pathname) ? setAffiliate(true) : setAffiliate(false)
    }, [pathname])

    if(affiliate || pathname.includes('build-your-own')) return null

  return (
    <div className={cn('')}>
      {children}
    </div>
  );
}